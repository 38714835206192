import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import friends from '../../images/friends.svg'
import youTube from '../../images/youtube.svg'
import google from '../../images/google.svg'
import micro from '../../images/micro.svg'
import spaFlower from '../../images/spa-flower.svg'
import spotlight from '../../images/spotlight.svg'


import styles from './index.module.scss';

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="Introduction to Video Podcast | Ultimate Guide"
        description="An introduction to video podcasting, and a summery fo the current videocast climate."
          keywords={[`videocast`, `video podcast`, `Podcast citation`]}
        />

        <article>
          <h1>Video Podcast</h1>
          <div>
            <p>
              Many of us are familiar with podcasts, but we tend to think of
              audio episodes. In recent years, audio podcasts have taken the
              world by storm, but video podcasts are also growing in popularity.
              If you’re interested in video podcasting, or you’re not quite sure
              what they are or how they differ to regular podcasts, here’s a
              guide that will answer all your questions.

              <img src="/images/videocast.jpg" alt="TED videocasts"/>

            </p>


            <h2>What exactly is a video podcast?</h2>

            <p>
              Video podcasts are also known as vodcasts, vidcasts, and videocasts, are a podcast that <strong>combines both visual and audio </strong>
              media. When you stream or download a podcast, you usually plug
              your earphones in and then listen intently for half an hour or so.
              With a video podcast, you can see and hear at the same time.
              Video podcasting offers opportunities for all kinds of people to
              connect with an audience. Whether you’re looking for innovative
              ways to promote your business, you’re a vlogger, or you enjoy
              making short films, video podcasts offer an excellent medium to
              get your work, your brand or your interests across.
            </p>

            <h2>The advantages of video podcasts</h2>

            <div className={styles.threeItems}>

              <div className={styles.item}>
                <img src={friends} alt="Friends"/>
                <p>Deeper audience connection</p>
              </div>
              <div className={styles.item}>
                <img src={youTube} alt="Youtube"/>
                <p>Discoverable though YouTube</p>
              </div>
              <div className={styles.item}>
                <img src={google} alt="Google SEO"/>
                <p>Better SEO and engagement</p>
              </div>

            </div>

            <p>
              Podcasts have become valuable travel, commuting, and workout
              buddies in our modern lives, and they’re a great way to connect
              with an online community. While it may not be possible to watch a
              video while you’re jogging, video podcasts can be especially
              valuable because they allow for a deeper connection. When you’ve
              got both audio and visual content, you’re interacting in different
              ways, and you’re letting the viewer notice everything from facial
              expressions and visual cues to colours and landscapes. Consider
              the difference in listening to a personal trainer describe
              exercises to tone the abs and watching them do that exercise, or
              hearing somebody talk about an incredible outfit compared to them
              showing you every piece.
            </p>
            <p>
              If you’re using a podcast to promote services or products, there
              are also significant benefits to providing video and audio
              content. If you’ve got a video podcast on YouTube, for example,
              this can open you up to search engine optimisation and social
              media marketing benefits, which could increase sales and revenue.
              Even if you’re not trying to make money, and you want to share
              videos as a hobby, you can improve your subscriber numbers by
              using platforms like YouTube to share your podcasts.
            </p>


            <h2>Videocast Example</h2>
            <p>Below is an example Videocast (Still Untitled: The Adam Savage Project), Available on both YouTube and podcast format's. </p>
            <iframe width="100%" height="400" src="https://www.youtube.com/embed/7Bia-dNcBm4" frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>


            <h2>How to get started: a video podcasting guide</h2>
            <p>
              Before you start filming your video, it’s always useful to devote
              a bit of time to planning and figuring out what kind of content
              you want to produce and who you want your episodes to attract.
              Work on a theme, consider the type of subject matter you want to
              cover and try and work through the format of the video in your
              head before you move any further. If you’ve got fresh ideas, this
              will help to clarify the content and ensure you’re connecting with
              the right audience.
            </p>

            <p>
              Once you’ve got a plan, it’s time to film your video. You can use
              a professional camera, but modern smartphones are so advanced that
              they produce video content that is crisp enough to use in a video
              podcast. Once you’ve filmed your video, upload it to your
              computer. It’s unlikely, even if you’ve been practising over and
              over again, that the film will be flawless, and this is where
              editing comes in. You can use editing software to eliminate any
              glitches or pauses and polish your video before you share it. Some
              computers come with editing software, and there are also
              programmes you can download either for free or for a fee. Windows
              Movie Maker is available for Windows while iMovie is designed for
              Macs. Other options include Adobe Premiere Elements and CyberLink
              Power Detector. When you’re just starting with a video podcast,
              you might not want to invest in an expensive kit, and you can
              produce good quality content without doing this. Use what you’ve
              got, make use of free software and see how you get on. If your
              podcast takes off, or you’re enjoying it, and you want to upgrade
              your technology, you can always do this at a later date.
            </p>

            <p>
              When your video is ready, the next step is to share it, and you’ll
              need to decide whether to make the episode streamable or
              downloadable. You’ll also need to format the file size using a
              video encoder to make it suitable for mobile consumption. To get
              your podcast out there, find a host, for example, YouTube or
              Libsyn. If the site doesn’t give you an RSS feed, create one
              yourself. It’s also a good idea to think about naming your
              episodes and providing a very brief description. Once your video
              is available, you can promote it using social media, your blog,
              other podcasts or your website.
            </p>

            <h2>Video podcasting tips</h2>

            <div className={styles.threeItems}>

              <div className={styles.item}>
                <img src={micro} alt="Sound quality"/>
                <p>Focus on getting great quality, clear sound</p>
              </div>
              <div className={styles.item}>
                <img src={spotlight} alt="Light"/>
                <p>Use a well lit environment</p>
              </div>
              <div className={styles.item}>
                <img src={spaFlower} alt="Relax and breath"/>
                <p>Relax and connect with your viewers</p>
              </div>

            </div>

            <p>
              When you’re filming a video, your focus is likely to be on the
              visual quality of the content, but sound clarity is also
              essential. Bear this in mind when you’re filming, and record audio
              separately. When you’re editing, you can mute the camera audio and
              add you're recorded, edited audio later. To enhance the aesthetic,
              think about lighting. Lighting can make all the difference,
              especially if you’re talking about places and people, or you’re
              promoting products. If you can’t rely on natural light, it’s worth
              exploring lighting options, such as using a light kit.
            </p>

            <p>
              If you’re the star of your video podcast, plan, rehearse and take
              your time when you’re speaking. Don’t rush through a monologue at
              a hundred miles per hour. Speak clearly, maintain eye contact, and
              make sure you know exactly where the camera is at all times. Try
              and relax, breathe deeply, and enjoy the experience.
            </p>

            <p>
              It’s very common to start life with video podcasts that look more
              amateur than Oscar-worthy. Like most things in life, practice
              makes perfect, so keep going, hone your skills, and learn from
              podcasters you respect and admire. Engage with your viewers, read
              comments and take feedback on board, and aim to improve
              continually. Use reviews and ratings to get an idea of the most
              popular topics and to gain an insight into what kinds of formats
              and podcast durations work best.
            </p>

            <p>
              Most of us are familiar with podcasts, but for some of us, our
              experience is limited to audio episodes. Video podcasts offer an
              alternative to audio podcasts, and they enable you to watch, as
              well as listen. Vodcasts or videocasts are growing in popularity,
              and they have a range of uses and benefits for hobbyists, business
              owners and those who are looking to promote their vlogging and
              filmmaking skills. If you’d like to give video podcasting a go,
              hopefully, these steps will enable you to get started. Figure out
              what you want to talk about and what you want to gain from your
              video podcast, target an audience, and get filming.
            </p>
          </div>
        </article>
      </Layout>
    )
  }
}

export default Page
